<template>
    <div class="sectiontitle flex -wrap -space-between -align-end">
        <div class="sectiontitle__title col-11 col-6__m-up col-4__l-up pt-xxl pl-m">
            <slot name="left">
                <h2 class="mt-0">{{ title }}</h2>
            </slot>
        </div>
        <div class="sectiontitle__right col-11 col-8__m-up col-5__l-up color-secondary">
            <slot name="right">
                <div v-if="typeof text === 'string'" v-html="nl2br(text)" />
            </slot>
        </div>
    </div>
</template>

<script>
import { nl2br } from '@projectfive/services';
export default {
    props: {
        title: String,
        text: String,
    },
    methods: {
        nl2br,
    },
}
</script>

<style>
.sectiontitle__title {
    border-left: 0.2rem solid currentColor;
}
</style>
